import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Claim {
  static selector = ".claim";

  constructor(block, attrs) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageNode = this.block.querySelector(".claim__image");
    this.textNode = this.block.querySelector(".claim__text__text");

    this.attributes = attrs;
  }

  setupText = () => {
    this.textNode.innerHTML = this.attributes.text;
    this.textNode.dataset.translationId = this.attributes.translationID;
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();
      this.setupText();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
