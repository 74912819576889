import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class NextPage {
  static selector = ".next-page";

  constructor(
    block,
    { url, name, subtitle = "next", color, image, translationID }
  ) {
    this.block = block;
    this.block.innerHTML = layout;
    this.imageNode = this.block.querySelector(".next-page__image");
    this.titleNode = this.block.querySelector(".next-page__text__title");
    this.subtitleNode = this.block.querySelector(".next-page__text__subtitle");
    this.arrowNode = this.block.querySelector(".next-page__arrow");

    this.attributes = { url, name, subtitle, color, image, translationID };
  }

  setupItem = async (element) => {
    return new Promise(async (resolve) => {
      const imageNode =
        this.imageTemplate.content.firstElementChild.cloneNode(true);
      if (imageNode) {
        await new CustomImage(imageNode, element).onReady();

        resolve(imageNode);
      } else {
        reject(false);
      }
    });
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();
      this.titleNode.textContent = this.attributes.name;
      this.titleNode.dataset.translationId = this.attributes.translationID;
      this.subtitleNode.textContent = this.attributes.subtitle;
      this.arrowNode.href = `/${this.attributes.url}/`;
      this.block.classList.add(`next-page--${this.attributes.color}`);

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
