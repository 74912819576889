import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block13 {
  static selector = ".block-13";

  constructor(block, { image }) {
    this.block = block;
    this.block.innerHTML = layout;
    this.imageNode = this.block.querySelector(".block-13__image");

    this.attributes = {
      image,
    };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
