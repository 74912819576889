import "./style.css";
import layout from "./layout.html";

export default class Quote {
  static selector = ".quote";

  constructor(block, { text, color = "blue", translationID }) {
    this.block = block;
    this.color = color;
    this.text = text;
    this.translationID = translationID;

    this.block.innerHTML = layout;

    this.textNode = this.block.querySelector(".quote__text");
    if (translationID) {
      this.textNode.dataset.translationId = translationID;
    }
  }

  setupText = (text) => {
    if (text) {
      this.textNode.innerHTML = text;
    } else {
      this.textNode.remove();
    }
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      this.setupText(this.text);

      this.block.classList.add(`quote--${this.color}`);

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
