import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import Quote from "@blocks/Quote";

export default class Block6 {
  static selector = ".block-6";

  constructor(block, attrs) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageNode = this.block.querySelector(".block-6__image");
    this.imageSmallNode = this.block.querySelector(".block-6__image-small");
    this.quoteNode = this.block.querySelector(".block-6__quote");

    this.attributes = attrs;
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();
      await new CustomImage(
        this.imageSmallNode,
        this.attributes.imageSmall
      ).onReady();
      await new Quote(this.quoteNode, this.attributes.quote).onReady();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
