import "./style.css";
import layout from "./layout.html";

export default class Intro {
  static selector = ".intro";

  constructor(block, attrs) {
    this.block = block;
    this.block.innerHTML = layout;
  }

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      resolve();
    });
  };
}
