import Lenis from "@studio-freight/lenis";
import { getRaf } from "@app";
import { laptop } from "@app/breakpoints";

export default class Scroll {
  constructor() {
    this.positionChangeCallbacks = [];
    this.x = window.scrollX;
  }

  initScroll = () => {
    this.raf?.unregister("scroll");

    if (this.lenis) {
      this.lenis.destroy();
    }

    this.lenis = new Lenis({
      orientation: this.orientation,
      gestureOrientation: "both",
    });
    this.lenis.setScroll(this.x, 0);

    this.raf?.register("scroll", this.render);

    this.lenis.on("scroll", this.onScroll);
  };

  start = () => {
    this.lenis.start();
  };

  stop = () => {
    this.lenis.stop();
  };

  reset = () => {
    this.update();
    this.scrollTo(0, { immediate: true });
  };

  update = () => {
    this.lenis.resize();
  };

  registerOnScrollPositionChange = (callback) => {
    this.positionChangeCallbacks.push(callback);
  };

  scrollTo = (target, options = {}) => {
    this.lenis.scrollTo(target, options);
  };

  render = (timestamp) => {
    this.lenis.raf(timestamp);
  };

  onScroll = (event) => {
    const {
      velocity,
      animatedScroll,
      direction,
      dimensions: { scrollWidth, width },
    } = event;
    const progress = animatedScroll / (scrollWidth - width);

    this.positionChangeCallbacks.forEach((callback) => {
      callback({
        velocity,
        x: animatedScroll,
        progress,
        direction,
      });
    });
  };

  onReady = () => {
    this.raf = getRaf();
    this.orientation = undefined;
    this.onResize();
    this.update();
  };

  onResize = () => {
    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const orientation =
      this.windowSize.width < laptop ? "vertical" : "horizontal";
    if (orientation !== this.orientation) {
      this.orientation = orientation;
      this.initScroll();
    }
  };
}
