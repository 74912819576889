import "./style.css";

export default class PageTransitioner {
  constructor(block) {
    this.block = block;

    this.transitionDuration = 800;
  }

  show = () => {
    return new Promise((resolve, reject) => {
      this.block.classList.remove("page-transitioner--hidden");
      setTimeout(() => {
        resolve();
      }, this.transitionDuration * 0.8);
    });
  };

  hide = () => {
    return new Promise((resolve, reject) => {
      this.block.classList.add("page-transitioner--hidden");

      setTimeout(() => {
        resolve();
      }, this.transitionDuration * 0.8);
    });
  };
}
