const easeInOutCirc = (pos) => {
  if ((pos /= 0.5) < 1) return -0.5 * (Math.sqrt(1 - pos * pos) - 1);
  return 0.5 * (Math.sqrt(1 - (pos -= 2) * pos) + 1);
};

const linear = (t) => {
  return t;
};

const easeInQuad = (t) => {
  return t * t;
};

const easeOutQuad = (t) => {
  return t * (2 - t);
};

const easeInOutQuad = (t) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

const easeInCubic = (t) => {
  return t * t * t;
};

const easeOutCubic = (t) => {
  return --t * t * t + 1;
};

const easeInOutCubic = (t) => {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

const easeInQuart = (t) => {
  return t * t * t * t;
};

const easeOutQuart = (t) => {
  return 1 - --t * t * t * t;
};

const easeInOutQuart = (t) => {
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
};

const easeInQuint = (t) => {
  return t * t * t * t * t;
};

const easeOutQuint = (t) => {
  return 1 + --t * t * t * t * t;
};

const easeInOutQuint = (t) => {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};

const easeInExpo = (t) => {
  return 0 === t ? 0 : Math.pow(2, 10 * (t - 1));
};

const easeOutExpo = (t) => {
  return 1 === t ? 1 : 1 - Math.pow(2, -10 * t);
};

const easeInOutExpo = (t) => {
  return 0 === t
    ? 0
    : 1 === t
    ? 1
    : (t /= 0.5) < 1
    ? 0.5 * Math.pow(2, 10 * (t - 1))
    : 0.5 * (2 - Math.pow(2, -10 * --t));
};

export {
  easeInQuad,
  easeOutQuad,
  easeInOutQuad,
  easeInCubic,
  easeOutCubic,
  easeInOutCubic,
  easeInQuart,
  easeOutQuart,
  easeInOutQuart,
  easeInQuint,
  easeOutQuint,
  easeInOutQuint,
  easeInOutCirc,
  easeInExpo,
  easeOutExpo,
  easeInOutExpo,
};
