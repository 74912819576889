import "./style.css";
import layout from "./layout.html";

export default class BookingInfo {
  static selector = ".booking-info";

  constructor(block, { info }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.itemsNode = this.block.querySelector(".booking-info__data__items");
    this.itemTemplate = this.block.querySelector(
      "#booking-info__item-template"
    );

    this.attributes = { info };
  }

  setupItem = (info) => {
    const infoItemNode =
      this.itemTemplate.content.firstElementChild.cloneNode(true);
    if (infoItemNode) {
      infoItemNode.innerHTML = info.text;
      infoItemNode.dataset.translationId = info.translationID;

      return infoItemNode;
    }

    return null;
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      this.attributes.info.forEach((item) => {
        const itemNode = this.setupItem(item);
        if (itemNode) {
          this.itemsNode.appendChild(itemNode);
        }
      });

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      resolve();
    });
  };
}
