import "./style.css";
import data from "@data/paesaggio.json";
import PageInSequence from "@page_templates/PageInSequence";

export default class Paesaggio extends PageInSequence {
  static selector = ".paesaggio";

  constructor(block) {
    super(block, data);
  }

  onReady() {}

  onComplete = () => {};

  onResize() {}

  onDestroy() {}
}
