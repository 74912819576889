import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block9 {
  static selector = ".block-9";

  constructor(block, { image, variant = null }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageNode = this.block.querySelector(".block-9__image");

    this.attributes = { image, variant };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();
      if (this.attributes.variant) {
        this.block.classList.add(`block-9--variant-${this.attributes.variant}`);
      }
      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
