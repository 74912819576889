import "./style.css";
import layout from "./layout.html";

export default class LanguageSelector {
  static selector = ".language-selector";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.buttonNode = block.querySelector(".language-selector__text");

    this.color = block.dataset.color ? block.dataset.color : "blue";

    this.mapping = {
      en: {
        from: "eng",
        to: "ita",
        toKey: "it",
      },
      it: {
        from: "ita",
        to: "eng",
        toKey: "en",
      },
    };
  }

  onPageTranslationComplete = (lang) => {
    lang = lang === "" ? "it" : lang;
    this.buttonNode.textContent = this.mapping[lang].to;
    this.buttonNode.href = `#${this.mapping[lang].toKey}`;
    this.buttonNode.dataset.lang = this.mapping[lang].toKey;
  };

  setup = () => {
    this.block.classList.add(`language-selector--${this.color}`);
  };

  onReady = () => {
    return new Promise((resolve) => {
      this.mounted = true;

      this.setup();

      resolve();
    });
  };
}
