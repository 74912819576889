import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block3 {
  static selector = ".block-3";

  constructor(block, { images, variant = null }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageTemplate = this.block.querySelector("#block-3__image-template");

    this.attributes = { images, variant };
  }

  setupItem = async (element) => {
    return new Promise(async (resolve) => {
      const imageNode =
        this.imageTemplate.content.firstElementChild.cloneNode(true);
      if (imageNode) {
        await new CustomImage(imageNode, element).onReady();

        resolve(imageNode);
      } else {
        reject(false);
      }
    });
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      this.attributes.images.forEach(async (element) => {
        const imageNode = await this.setupItem(element);
        if (imageNode) {
          this.block.appendChild(imageNode);
        }
      });

      if (this.attributes.variant) {
        this.block.classList.add(`block-3--variant-${this.attributes.variant}`);
      }
      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
