import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Cover {
  static selector = ".cover";

  constructor(block, { title, image = null, inverted = false }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageTemplate = this.block.querySelector("#cover__image-template");
    this.titleTextNode = this.block.querySelector(".cover__title__text");

    this.attributes = {
      title,
      image,
      inverted,
    };
  }

  setupTitle = () => {
    this.titleTextNode.textContent = this.attributes.title.text;
    this.titleTextNode.dataset.translationId =
      this.attributes.title.translationID;
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      if (this.attributes.image) {
        const imageNode =
          this.imageTemplate.content.firstElementChild.cloneNode(true);
        if (imageNode) {
          await new CustomImage(imageNode, this.attributes.image).onReady();

          imageNode.classList.toggle(
            "cover__image--large",
            this.attributes.image.large
          );

          if (this.attributes.image.small) {
            imageNode.classList.add("cover__image--small");
          }

          this.block.appendChild(imageNode);
        }
      }

      this.block.classList.toggle("cover--inverted", this.attributes.inverted);

      this.setupTitle();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      resolve();
    });
  };
}
