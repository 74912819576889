import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block13 {
  static selector = ".block-14";

  constructor(block, { image, largeImage }) {
    this.block = block;
    this.block.innerHTML = layout;
    this.imageNode = this.block.querySelector(".block-14__image");
    this.largeImageNode = this.block.querySelector(".block-14__large-image");

    this.attributes = {
      image,
      largeImage,
    };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();
      await new CustomImage(
        this.largeImageNode,
        this.attributes.largeImage
      ).onReady();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
