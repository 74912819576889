import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block12 {
  static selector = ".block-12";

  constructor(
    block,
    {
      text,
      backgroundColor = "blue",
      textColor = "white",
      image,
      translationID,
      variant = 1,
    }
  ) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageTemplate = this.block.querySelector("#block-12__image-template");
    this.textNode = this.block.querySelector(".block-12__text");

    this.attributes = {
      text,
      backgroundColor,
      textColor,
      image,
      translationID,
      variant,
    };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      this.textNode.classList.add(`bc-${this.attributes.backgroundColor}`);
      this.textNode.querySelector("p").innerHTML = this.attributes.text;
      this.textNode.classList.add(`c-${this.attributes.textColor}`);
      this.textNode.querySelector("p").dataset.translationId =
        this.attributes.translationID;

      if (this.attributes.image) {
        const imageNode =
          this.imageTemplate.content.firstElementChild.cloneNode(true);
        if (imageNode) {
          await new CustomImage(imageNode, this.attributes.image).onReady();

          this.block.appendChild(imageNode);
        }
      }

      this.block.classList.add(`block-12--variant-${this.attributes.variant}`);

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
