import "./style.css";
import layout from "./layout.html";
import { getInstance, getScroll } from "@app";
import CustomImage from "@blocks/Image";
import pages from "@data/pages_1.json";

export default class Nav {
  static selector = ".nav";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.navItemTemplate = block.querySelector("#nav-item-template");
    this.navItemsNode = block.querySelector(".nav__items");

    this.animatedTextsInstances = [];
    this.opened = false;
    this.duration = 1200;
    this.index = 0;

    this.chapters = pages;
  }

  setToggleButtons = () => {
    this.toggleButtons = document.querySelectorAll(".toggle-nav");

    this.toggleButtons.forEach((button) => {
      button.addEventListener("click", this.onToggleNavClicked);
    });
  };

  hide = () => {
    if (this.socials) {
      this.socials.style.opacity = null;
    }

    this.scroll.start();

    this.animatedTextsInstances.forEach((instance) => {
      if (instance.hide) {
        instance.hide();
      }
    });

    this.block.style.pointerEvents = null;

    this.block.addEventListener("transitionend", this.hidden);
    setTimeout(() => {
      this.block.style.zIndex = "var(--nav-z-index)";
      this.block.style.opacity = 1;
      document.body.classList.remove("nav-visible");
    }, this.duration / 4);
  };

  hidden = (e) => {
    if (e.propertyName == "clip-path") {
      this.block.removeEventListener("transitionend", this.hidden);
      this.block.style.zIndex = null;
      this.block.style.opacity = null;
    }
  };

  show = () => {
    document.body.classList.add("nav-visible");
    this.scroll.stop();

    setTimeout(() => {
      this.animatedTextsInstances.forEach((instance) => {
        if (instance.show) {
          instance.show();
        }
      });
    }, this.duration / 2.5);
  };

  changeState = () => {
    if (!this.opened) {
      this.show();
    } else {
      this.hide();
    }

    this.opened = !this.opened;
  };

  updateIndex = (chapterNumber) => {
    chapterNumber = parseInt(chapterNumber, 10) - 1;
    this.index = chapterNumber;
    this.carousel.setIndex(-this.index);
    this.navItemNodes.forEach((node, index) => {
      node.classList.toggle(
        "nav__menu__items__item--current",
        index === Math.abs(this.index)
      );
    });
  };

  onNavItemClicked = (chapterNumber) => {
    this.updateIndex(chapterNumber);
  };

  onToggleNavClicked = () => {
    this.changeState();
  };

  setupItem = async (chapter) => {
    return new Promise(async (resolve) => {
      const itemNode =
        this.navItemTemplate.content.firstElementChild.cloneNode(true);
      if (itemNode) {
        const imageNode = itemNode.querySelector(".image");
        await new CustomImage(imageNode, chapter.image).onReady();

        const titleNode = itemNode.querySelector(".nav__items__item__title");
        titleNode.textContent = `${chapter.title}`;
        titleNode.dataset.translationId = chapter.translationID;

        itemNode.href = chapter.url;

        resolve(itemNode);
      } else {
        reject(false);
      }
    });
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      this.chapters.forEach(async (chapter) => {
        const itemNode = await this.setupItem(chapter);
        if (itemNode) {
          this.navItemsNode.appendChild(itemNode);
        }
      });

      resolve();
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;
      this.scroll = getScroll();

      await this.setup();

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.completed = true;

      this.setToggleButtons();

      this.animatedTexts = this.block.querySelectorAll(".animated-text");
      if (this.animatedTexts) {
        this.animatedTexts.forEach((node) => {
          const instance = getInstance(node);

          if (instance) {
            this.animatedTextsInstances.push(instance);
          }
        });
      }

      resolve();
    });
  };

  onPageChangeComplete = () => {
    return new Promise(async (resolve, reject) => {
      //this.updateActiveNavLink();

      this.setToggleButtons();
      resolve();
    });
  };
}
