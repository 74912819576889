import translations from "@data/translations";
import { getInstance } from "@app";

export default class Translator {
  constructor() {
    this.callbacks = [];
  }

  translate = () => {
    return new Promise(async (resolve) => {
      this.currentLanguage = window.location.hash.substring(1);

      if (this.currentLanguage.length > 0 && this.currentLanguage) {
        const textsToTranslate = document.querySelectorAll(
          "[data-translation-id]"
        );

        await Promise.all(
          Array.from(textsToTranslate).map(async (text) => {
            const translationID = parseInt(text.dataset.translationId, 10);
            text.innerHTML = translations[translationID][this.currentLanguage];

            /*const textInstance = getInstance(text);
            if (
              textInstance &&
              textInstance.onTranslationComplete &&
              textInstance.mounted
              ) {
                textInstance.onTranslationComplete();
              }*/
          })
        );
      }
      resolve(this.currentLanguage);
    });
  };
}
