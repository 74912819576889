import "./style.css";
import layout from "./layout.html";
import Cover from "@blocks/Cover";
import Block1 from "@blocks/Block1";
import Block2 from "@blocks/Block2";
import Block3 from "@blocks/Block3";
import Block4 from "@blocks/Block4";
import Block5 from "@blocks/Block5";
import Block6 from "@blocks/Block6";
import Block7 from "@blocks/Block7";
import Block8 from "@blocks/Block8";
import Block9 from "@blocks/Block9";
import Block10 from "@blocks/Block10";
import Block11 from "@blocks/Block11";
import Block12 from "@blocks/Block12";
import Block13 from "@blocks/Block13";
import Block14 from "@blocks/Block14";
import Quote from "@blocks/Quote";
import Claim from "@blocks/Claim";
import BookingInfo from "@blocks/BookingInfo";
import Directions from "@blocks/Directions";
import NextPage from "@blocks/NextPage";
import HouseMap from "@blocks/HouseMap";
import History from "@blocks/History";
import Italy from "@blocks/Italy";

export default class PageInSequence {
  constructor(block, data) {
    this.block = block;
    this.block.classList.add("page-in-sequence");
    this.sections = data.blocks;
    this.nextPage = data.nextPage;

    this.block.innerHTML = layout;
    this.sectionTemplate = block.querySelector("#section-template");
    this.contentNode = block.querySelector(".main__content");

    this.classesMap = {
      cover: Cover,
      quote: Quote,
      claim: Claim,
      "booking-info": BookingInfo,
      "block-1": Block1,
      "block-2": Block2,
      "block-3": Block3,
      "block-4": Block4,
      "block-5": Block5,
      "block-6": Block6,
      "block-7": Block7,
      "block-8": Block8,
      "block-9": Block9,
      "block-10": Block10,
      "block-11": Block11,
      "block-12": Block12,
      "block-13": Block13,
      "block-14": Block14,
      directions: Directions,
      "house-map": HouseMap,
      history: History,
      italy: Italy,
    };
  }

  setupNextPageNode = () => {
    return new Promise(async (resolve) => {
      const nextPageNode = document.createElement("section");
      nextPageNode.classList.add("next-page");
      await new NextPage(nextPageNode, this.nextPage).onReady();
      resolve(nextPageNode);
    });
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await Promise.all(
        this.sections.map(async (section) => {
          const { name, attrs } = section;
          const sectionNode =
            this.sectionTemplate.content.firstElementChild.cloneNode(true);
          sectionNode.classList.add(name);
          this.contentNode.appendChild(sectionNode);

          await new this.classesMap[name](sectionNode, attrs).onReady();
        })
      );

      const nextPageNode = await this.setupNextPageNode();
      this.block.appendChild(nextPageNode);

      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {};

  onDestroy = () => {};
}
