import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class HouseMap {
  static selector = ".house-map";

  constructor(block, { info }) {
    this.block = block;
    this.block.innerHTML = layout;
    this.imagesNode = block.querySelector(".house-map__images");
    this.itemsNode = block.querySelector(".house-map__items");
    this.itemTemplate = block.querySelector("#house-map__item-template");
    this.imageTemplate = block.querySelector("#house-map__image-template");
    this.itemNodes = [];
    this.imageNodes = [];

    this.images = [
      {
        title: "Intera Casa",
        translationID: 30,
        image: {
          src: "https://i.imgur.com/EELQ3gn.png",
          alt: "",
        },
      },
      {
        title: "Piano Terra",
        translationID: 31,
        image: {
          src: "https://i.imgur.com/FUp8LRf.png",
          alt: "",
        },
      },
      {
        title: "Primo Piano",
        translationID: 32,
        image: {
          src: "https://i.imgur.com/EOSKMnW.png",
          alt: "",
        },
      },
    ];

    this.attributes = { images: this.images };
    this.index = 0;
  }

  updateUI = () => {
    this.itemNodes.forEach((node, index) => {
      node.classList.toggle(
        "house-map__items__item--active",
        index === this.index
      );
    });

    this.imageNodes.forEach((node, index) => {
      node.classList.toggle(
        "house-map__images__image--active",
        index === this.index
      );

      node.classList.toggle(
        "house-map__images__image--visible",
        index < this.index && index === 0
      );
    });
  };

  updateIndex = (newIndex) => {
    this.index = newIndex;
  };

  onItemNodeClicked = (event) => {
    const target = event.currentTarget;
    const newIndex = parseInt(target.dataset.index);

    if (newIndex !== this.index) {
      this.updateIndex(newIndex);
      this.updateUI();
    }
  };

  setupImage = (image, index) => {
    return new Promise(async (resolve) => {
      const imageNode =
        this.imageTemplate.content.firstElementChild.cloneNode(true);
      if (imageNode) {
        imageNode.dataset.index = index;
        await new CustomImage(imageNode, image).onReady();
        resolve(imageNode);
      }

      resolve(null);
    });
  };

  setupItem = (title, translationID, index) => {
    const itemNode =
      this.itemTemplate.content.firstElementChild.cloneNode(true);
    if (itemNode) {
      itemNode.dataset.index = index;
      const numberNode = itemNode.querySelector(
        ".house-map__items__item__number"
      );
      numberNode.textContent = `0${index + 1}`;

      const titleNode = itemNode.querySelector(
        ".house-map__items__item__title"
      );
      titleNode.textContent = title;
      titleNode.dataset.translationId = translationID;

      itemNode.addEventListener("click", this.onItemNodeClicked);

      return itemNode;
    }

    return null;
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      for (const [index, image] of this.attributes.images.entries()) {
        const itemNode = this.setupItem(
          image.title,
          image.translationID,
          index
        );
        if (itemNode) {
          this.itemsNode.appendChild(itemNode);
          this.itemNodes.push(itemNode);
        }

        const imageNode = await this.setupImage(image.image);
        if (imageNode) {
          this.imagesNode.appendChild(imageNode);
          this.imageNodes.push(imageNode);
        }
      }

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      this.updateIndex(this.index);
      this.updateUI();
      resolve();
    });
  };
}
