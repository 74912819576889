const listeners = [];
const languageChangeListeners = [];

export const push = (route) => {
  const previousRoute = window.location.pathname;

  if (previousRoute.replace(/\/$/, "") === route.replace(/\/$/, "")) {
    return;
  }

  window.history.pushState(null, null, route);

  listeners.forEach((listener) =>
    listener(previousRoute.replace(/\/$/, ""), route.replace(/\/$/, ""))
  );
};

export const listen = (callback) => {
  listeners.push(callback);
};

export const pushLanguage = (hash) => {
  const currentHash = window.location.hash;
  const currentRoute = window.location.pathname;

  if (
    hash === currentHash ||
    (hash === "#it" && window.location.hash.length < 1)
  ) {
    return;
  }

  window.history.pushState(
    null,
    null,
    `${currentRoute.replace(/\/$/, "")}/${hash}`
  );

  languageChangeListeners.forEach((listener) => {
    listener();
  });
};

export const listenLanguageChange = (callback) => {
  languageChangeListeners.push(callback);
};
