import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block7 {
  static selector = ".block-7";

  constructor(block, attrs) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageNode = this.block.querySelector(".block-7__image");

    this.attributes = attrs;
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
