import "./style.css";
import { clamp, map } from "@utils/math.js";
import { easeInOutExpo } from "@utils/easing.js";
import { getRaf } from "@app";
import layout from "./layout.html";
import AnimatedText from "@components/AnimatedText";

import neumaticCompressedRegularImageSource from "@assets/font/NeumaticCompressed-Regular.png";

const MSDF_ATLASES_TO_LOAD = [neumaticCompressedRegularImageSource];

export default class Loader {
  static selector = ".loader";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;

    this.resourcesToLoad = 0;

    this.resourcesLoaded = 0;
    this.reasourcesProgress0 = 0;
    this.reasourcesProgress = 0;

    this.preloadedResources = {};

    this.animation = {
      duration: 2400,
      ease: easeInOutExpo,
      fromValue: 0,
      toValue: 33,
      current: 0,
      setValue: (progress) => {
        this.animation.current = clamp(map(progress, 0, 0.999, 0, 1), 0, 1);
      },
    };
  }

  loadMSDFAtlases = async () => {
    this.resourcesToLoad += MSDF_ATLASES_TO_LOAD.length;

    const loadMSDFAtlasPromises = [];

    MSDF_ATLASES_TO_LOAD.forEach((imageToLoad) => {
      let image = new Image();
      image.crossOrigin = "anonymous";

      loadMSDFAtlasPromises.push(
        new Promise((resolve) => {
          const onImageLoaded = () => {
            this.resourcesLoaded += 1;

            this.reasourcesProgress =
              this.resourcesLoaded / this.resourcesToLoad;

            this.preloadedResources[imageToLoad] = image;

            // console.log(
            //   "%c Atlas ",
            //   "background: lightgray; color: black",
            //   "loaded"
            // );

            resolve();
          };

          image.addEventListener("load", onImageLoaded);
          image.src = imageToLoad;
        })
      );
    });

    return Promise.all(loadMSDFAtlasPromises);
  };

  loadFontFaces = async () => {
    this.textNode = this.block.querySelector(".loader__header__text");

    await document.fonts.ready;

    this.animatedText = new AnimatedText(this.textNode);
    this.textNodeContent = this.textNode.querySelector(".animated-text__row");
    this.textNodeContent.textContent = "1990";
    await this.animatedText.onReady();
    await this.animatedText.show();
  };

  loadImages = () => {
    this.imagesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    this.resourcesToLoad += this.imagesToLoad.length;

    const loadImagePromises = [];

    this.imagesToLoad.forEach((imageToLoad) => {
      loadImagePromises.push(
        new Promise((resolve) => {
          const onImageLoaded = () => {
            this.resourcesLoaded += 1;

            this.reasourcesProgress =
              this.resourcesLoaded / this.resourcesToLoad;

            this.preloadedResources[imageToLoad.src] = imageToLoad;

            // console.log(
            //   "%c Image ",
            //   "background: yellow; color: black",
            //   "loaded"
            // );

            resolve();
          };

          if (imageToLoad.complete) {
            onImageLoaded();
          } else {
            imageToLoad.addEventListener("load", onImageLoaded);
          }
        })
      );
    });

    return Promise.all(loadImagePromises);
  };

  load = () => {
    let transitionPromise = {};
    transitionPromise.promise = new Promise(
      (resolve) => (transitionPromise.resolve = resolve)
    );
    this.raf = getRaf();
    this.raf.register("loader", (timestamp) => {
      this.render(timestamp, transitionPromise);
    });

    const loadMSDFAtlasesPromise = this.loadMSDFAtlases();
    const loadImagesPromise = this.loadImages();

    return Promise.all([
      loadMSDFAtlasesPromise,
      loadImagesPromise,
      transitionPromise.promise,
    ]);
  };

  hide = () => {
    return new Promise(async (resolve) => {
      this.animatedText.hideToTop();
      this.block.style.backgroundColor = `transparent`;

      setTimeout(async () => {
        this.block.classList.add("loader--hidden");
        document.body.classList.add("loader-hidden");
        await this.handleTransitionEnd();

        resolve();
      }, 400);
    });
  };

  handleTransitionEnd = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.block.style.pointerEvents = "none";
        this.block.classList.add("loader--loaded");

        resolve();
      }, 1100);
    });
  };

  animate = () => {
    const textProgress = Math.round(
      this.animation.toValue * this.animation.current
    );

    this.textNodeContent.textContent = `${1990 + textProgress}`;
  };

  render = async (timestamp, promise) => {
    if (!this.animation.startTimestamp) {
      this.animation.startTimestamp = timestamp;
    }

    const timeProgress = this.animation.ease(
      clamp(
        (timestamp - this.animation.startTimestamp) / this.animation.duration,
        0,
        1
      )
    );

    this.reasourcesProgress0 =
      this.reasourcesProgress0 +
      (this.reasourcesProgress - this.reasourcesProgress0) * 0.09;
    this.progress = Math.min(timeProgress, this.reasourcesProgress0);
    this.animation.setValue(this.progress);

    this.animate();

    if (this.animation.current === 1) {
      this.raf.unregister("loader");
      promise.resolve();
    }
  };
}
