import "./style.css";
import layout from "./layout.html";

export default class History {
  static selector = ".history";

  constructor(block, { info }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.infoNode = this.block.querySelector(".history__info");
    this.infoItemTemplate = this.block.querySelector(
      "#history__info__item-template"
    );

    this.attributes = { info };
  }

  setupInfoParagraph = (paragraph) => {
    const node = document.createElement("p");
    node.innerHTML = paragraph.text;
    node.dataset.translationId = paragraph.translationID;
    return node;
  };

  setupInfo = (info) => {
    const infoItemNode =
      this.infoItemTemplate.content.firstElementChild.cloneNode(true);
    if (infoItemNode) {
      info.paragraphs.forEach((paragraph) => {
        const paragraphNode = this.setupInfoParagraph(paragraph);
        if (paragraphNode) {
          infoItemNode.appendChild(paragraphNode);
        }
      });

      return infoItemNode;
    }

    return null;
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      const itemNode = this.setupInfo(this.attributes.info);
      if (itemNode) {
        this.infoNode.appendChild(itemNode);
      }

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      resolve();
    });
  };
}
