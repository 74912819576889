import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import Quote from "@blocks/Quote";

export default class Block11 {
  static selector = ".block-11";

  constructor(block, attrs) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageLargeNode = this.block.querySelector(
      ".block-11__image-large.image"
    );
    this.quoteNode = this.block.querySelector(".block-11__quote");

    this.attributes = attrs;
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(
        this.imageLargeNode,
        this.attributes.imageLarge
      ).onReady();
      await new Quote(this.quoteNode, this.attributes.quote.attrs).onReady();

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
