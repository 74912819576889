import "./style.css";
import layout from "./layout.html";

export default class Directions {
  static selector = ".directions";

  constructor(block, attrs) {
    this.block = block;

    this.block.innerHTML = layout;

    this.attributes = attrs;
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      resolve();
    });
  };
}
