const mod = (n, m) => ((n % m) + m) % m;

const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

const clamp = (val, min = 0, max = 1) => Math.max(min, Math.min(max, val));

const lerp = (a, b, n) => (1 - n) * a + n * b;

// returns a random number between 0 (inclusive) and 1 (exclusive)
const random = () => {
  return Math.random();
};

// returns a random number between min (inclusive) and max (exclusive)
const randomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
};

// returns a random integer between 0 (inclusive) and max (exclusive)
const randomInt = (max) => {
  return Math.floor(Math.random() * max);
};

// returns a random integer between 0 (inclusive) and max (exclusive) excluding a specified value
const randomIntExclusively = (max, exclude) => {
  var num = Math.floor(Math.random() * max);
  return num === exclude ? randomIntExclusively(max, exclude) : num;
};

// returns a random integer between min (inclusive) and max (exclusive)
const randomIntFromRange = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

// returns a random integer between min (inclusive) and max (exclusive) excluding a specified value
const randomIntFromRangeExclusively = (min, max, exclude) => {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num === exclude
    ? randomIntFromRangeExclusively(min, max, exclude)
    : num;
};

export {
  random,
  randomInt,
  randomIntFromRange,
  randomIntFromRangeExclusively,
  randomArbitrary,
  randomIntExclusively,
  mod,
  map,
  clamp,
  lerp,
};
