import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import Quote from "@blocks/Quote";

export default class Block8 {
  static selector = ".block-8";

  constructor(block, { largeImages, image, text, inverted = false, quote }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageAndTextNode = this.block.querySelector(
      ".block-8__image-and-text"
    );

    this.imageTemplate = this.block.querySelector("#block-8__image-template");
    this.textTemplate = this.block.querySelector("#block-8__text-template");
    this.largeImageTemplate = this.block.querySelector(
      "#block-8__large-image-template"
    );
    this.quoteTemplate = this.block.querySelector("#block-8__quote-template");

    this.attributes = { largeImages, image, text, inverted, quote };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      if (this.attributes.largeImages) {
        this.attributes.largeImages.forEach(async (image) => {
          const largeImageNode =
            this.largeImageTemplate.content.firstElementChild.cloneNode(true);
          if (largeImageNode) {
            await new CustomImage(largeImageNode, image).onReady();

            this.block.prepend(largeImageNode);
          }
        });
      }

      if (this.attributes.image) {
        const imageNode =
          this.imageTemplate.content.firstElementChild.cloneNode(true);
        if (imageNode) {
          await new CustomImage(imageNode, this.attributes.image).onReady();
          this.imageAndTextNode.appendChild(imageNode);
        }
      }

      if (this.attributes.text) {
        const textNode =
          this.textTemplate.content.firstElementChild.cloneNode(true);
        if (textNode) {
          textNode.innerHTML = this.attributes.text.text;
          if (this.attributes.text.translationID) {
            textNode.dataset.translationId = this.attributes.text.translationID;
          }
          this.imageAndTextNode.prepend(textNode);
          this.block.classList.add("block-8--with-text");
        }
      }

      if (this.attributes.quote) {
        const quoteNode =
          this.quoteTemplate.content.firstElementChild.cloneNode(true);
        if (quoteNode) {
          await new Quote(quoteNode, this.attributes.quote).onReady();
          this.imageAndTextNode.prepend(quoteNode);
          this.block.classList.add("block-8--with-quote");
        }
      }

      this.imageAndTextNode.classList.toggle(
        "block-8__image-and-text--inverted",
        this.attributes.inverted
      );

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
