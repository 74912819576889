import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import pages from "@data/pages_1.json";

export default class PageCards {
  static selector = ".page-cards";

  constructor(block, attrs) {
    this.block = block;
    this.block.innerHTML = layout;
    this.cardTemplate = this.block.querySelector("#page-card-template");

    this.pages = pages;
  }

  setupCard = async (page) => {
    return new Promise(async (resolve) => {
      const cardNode =
        this.cardTemplate.content.firstElementChild.cloneNode(true);
      if (cardNode) {
        cardNode.href = page.url;

        const cardTitleNode = cardNode.querySelector(
          ".page-cards__card__number-and-title__title"
        );
        cardTitleNode.textContent = page.title;
        cardTitleNode.dataset.translationId = page.translationID;

        const cardTitle1Node = cardNode.querySelector(
          ".page-cards__card__title"
        );
        cardTitle1Node.textContent = page.title;
        cardTitle1Node.dataset.translationId = page.translationID;

        const cardImageNode = cardNode.querySelector(
          ".page-cards__card__image"
        );
        await new CustomImage(cardImageNode, page.image).onReady();

        resolve(cardNode);
      }

      resolve(null);
    });
  };

  setup = async () => {
    return new Promise(async (resolve) => {
      this.pages.forEach(async (page) => {
        const cardNode = await this.setupCard(page);
        if (cardNode) {
          this.block.appendChild(cardNode);
        }
      });

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
