import "./style.css";
import layout from "./layout.html";
import { getScroll, getInstance, getObserver } from "@app";

export default class Header {
  static selector = ".header";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
  }

  updateHeaderHeight = () => {
    document.documentElement.style.setProperty(
      "--header-height",
      this.block.offsetHeight + "px"
    );
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      this.onResize();

      resolve();
    });
  };

  onComplete = () => {};

  onResize = () => {
    this.updateHeaderHeight();
  };
}
