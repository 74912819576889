import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";

export default class Block2 {
  static selector = ".block-2";

  constructor(block, { imageSmall, imageLarge, version = 1 }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageSmallNode = this.block.querySelector(".block-2__image-small");
    this.imageLargeNode = this.block.querySelector(".block-2__image-large");

    this.attributes = {
      imageSmall,
      imageLarge,
      version,
    };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await await new CustomImage(
        this.imageSmallNode,
        this.attributes.imageSmall
      ).onReady();

      await await new CustomImage(
        this.imageLargeNode,
        this.attributes.imageLarge
      ).onReady();

      this.block.classList.add(`block-2--v-${this.attributes.version}`);

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
