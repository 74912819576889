import "./style.css";
import layout from "./layout.html";
import CustomImage from "@blocks/Image";
import Quote from "@blocks/Quote";

export default class Block4 {
  static selector = ".block-4";

  constructor(block, { image, quote }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imageNode = this.block.querySelector(".image");
    this.quoteTemplate = this.block.querySelector("#block-4__quote-template");

    this.attributes = {
      image,
      quote,
    };
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      await new CustomImage(this.imageNode, this.attributes.image).onReady();

      if (this.attributes.quote) {
        const quoteNode =
          this.quoteTemplate.content.firstElementChild.cloneNode(true);
        if (quoteNode) {
          await new Quote(quoteNode, this.attributes.quote).onReady();
          this.block.appendChild(quoteNode);
          this.block.classList.add("block-4--with-layer");
        }
      }

      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;

      await this.setup();

      resolve();
    });
  };
}
