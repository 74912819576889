import "./style.css";
import layout from "./layout.html";

export default class CustomImage {
  static selector = ".image";

  constructor(block, { src, alt }) {
    this.block = block;

    this.block.innerHTML = layout;

    this.imgNode = this.block.querySelector("img");
    this.src = src;
    this.alt = alt;
  }

  setup = async () => {
    return new Promise(async (resolve) => {
      this.imgNode.setAttribute("crossOrigin", "anonymous");
      this.imgNode.setAttribute("src", this.src);
      this.imgNode.setAttribute("alt", this.alt);
      resolve();
    });
  };

  onReady = async () => {
    return new Promise(async (resolve) => {
      this.mounted = true;
      await this.setup();
      resolve();
    });
  };
}
