import { getScroll, getInstance } from "@app";
import { push, pushLanguage } from "@router/History.js";

export default class Anchor {
  static name = "Anchor";
  static selector = "a";

  constructor(block) {
    this.block = block;

    this.block.addEventListener("click", this.handleClick);
    this.block.addEventListener("keydown", this.handleKeydown);
  }

  isMailTo = () => this.block.href.startsWith("mailto:");
  isTel = () => this.block.href.startsWith("tel:");
  isTargetBlank = () =>
    !!this.block.target && this.block.target.toLowerCase() === "_blank";
  isLocalUrl = () => this.block.host === window.location.host;
  isSamePage = () => this.block.pathname === window.location.pathname;
  isAnchorToSection = () => this.block.hash.startsWith("#");
  isDownloadLink = () => this.block.getAttribute("download") !== null;
  isLinkToANewLanguage = () => this.block.dataset.lang;

  handleClick = (e) => {
    // short-circuit when:
    //  - we hare in wp-admin
    //  - is a target blank link
    //  - is an explicit hard-link
    //  - is not a local url
    //  - is an admin url
    //  - is mailto
    //  - is tel
    if (
      this.isTargetBlank() ||
      !this.isLocalUrl() ||
      this.isMailTo() ||
      this.isTel() ||
      this.isDownloadLink()
    ) {
      return;
    }

    // if is link to a new language
    if (this.isLinkToANewLanguage()) {
      e.preventDefault();
      pushLanguage(this.block.hash);
      return;
    }

    // if is link to current page
    if (this.isSamePage()) {
      if (this.isAnchorToSection()) {
        e.preventDefault();

        const section = document.querySelector(`${this.block.hash}`);
        if (section) {
          const scroll = getScroll();
          scroll.scrollTo(section, { immediate: true });

          // Hide Nav if visible
          if (document.body.classList.contains("nav-visible")) {
            const navInstance = getInstance(document.querySelector(".nav"));
            if (navInstance && navInstance.hide) {
              navInstance.changeState();
              navInstance.updateActiveNavLink();
            }
          }
        }
      }

      return;
    }

    e.preventDefault();
    let action = this.block.getAttribute("href");
    const actionHash =
      action.indexOf("#") > 0 ? action.substring(action.indexOf("#")) : null;
    const currentHash = window.location.hash;

    if (currentHash && currentHash.length > 0 && !actionHash) {
      action = action.replace(/\/$/, "") + "/" + currentHash;
    }
    push(action);
  };

  handleKeydown = (e) => {
    if (e.which !== 13) {
      return;
    }

    if (!this.isLocalUrl() || this.isAdminUrl()) {
      return;
    }

    e.preventDefault();

    window.App.navigateTo(this.block.href);
  };

  onDestroy = () => {
    this.block.removeEventListener("click", this.handleClick);
    this.block.removeEventListener("keydown", this.handleKeydown);
  };
}
