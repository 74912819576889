const translations = {
  0: {
    it: "ita",
    en: "eng",
  },

  1: {
    it: "benvenuti<br /> a “<b>la casa<br /> di maratea</b>",
    en: "Welcome <br /> to “<b>la casa<br /> di maratea</b>",
  },

  2: {
    it: "La <b>casa di maratea</b> è il<br /> prolungamento dello<br /> spirito della <b>minervetta</b>,<br/> in basILICATA",
    en: "La <b>Casa di Maratea</b><br /> is an extension of the<br /> spirits of the <b>Maison<br /> La Minervetta</b> in Sorrento",
  },

  3: {
    it: "una <b>casa</b> con una forte<br /> inclinazione estetica che invita<br /> a vivere più <b>intensamente</b>",
    en: "A <b>place</b> with strong<br /> aesthetic biases that invite you<br /> to live more <b>intensely</b>",
  },

  4: {
    it: "un <b>terrazzo</b> AFFACCIATO<br /> SUL <b>MARE</b> DOVE GODERSI<br /> I PIACERI DELLA <b>VITA</b>",
    en: "A <b>Terrace</b> where we can<br /> enjoy the pleasure of sharing<br /> or escaping from the world",
  },

  5: {
    it: "i <b>colori</b> si mescolano con<br /> gli innumerevoli <b>libri d’arte</b>,<br /> <b>oggetti da collezione</b><br /> di ettore sottsass, gio ponti,<br /> gae aulenti e gerrit rietveld",
    en: "<b>Colors</b> mix each other with<br /> countless <b>art books</b>,<br /> <b>collections of objects</b><br /> Ettore Sottsass, Gio Ponti,<br /> Gae Aulenti e Gerrit Rietveld",
  },

  6: {
    it: "<b>semplicità</b><br /> e <b>raffinatezza</b><br /> convivono nello<br /> stesso contesto",
    en: "<b>Simplicity</b><br /> and <b>refinement</b><br /> can coexist in the<br /> same environment",
  },

  7: {
    it: "<b>eclettismo</b> e <b>libertà</b><br /> si mescolano alla gioia<br /> e alla condivisione...",
    en: "<b>Eclecticism</b> and <b>freedom</b><br /> are mixed with joy<br /> and the sharing...",
  },

  8: {
    it: "...di un gusto<br /> immoderato per<br /> <b>oggetti delicati</b> con<br /> una propria <b>anima</b>.",
    en: "...of an immoderate<br /> taste for<br /> <b>sensitive objects,</b><br /> which have a <b>soul</b>.",
  },

  9: {
    it: "un’odissea stravagante<br /> permane nel <b>viaggio</b><br /> <b>interiore</b> che si vive<br /> all’interno della casa.",
    en: "An extravagant odyssey<br /> inhabits the <b>inner</b><br /> <b>journey</b> that take place<br /> at La Casa di Maratea.",
  },

  10: {
    it: "La Casa",
    en: "Home",
  },

  11: {
    it: "Interni",
    en: "Interiors",
  },

  12: {
    it: "Il Paesaggio",
    en: "Landscape",
  },

  13: {
    it: "Prenotazioni",
    en: "Book now",
  },

  14: {
    it: "La camera<br />di <b>Wanda</b>",
    en: "<b>Wanda</b>'s <br />room",
  },

  15: {
    it: "La camera<br />di <b>Marco</b>",
    en: "<b>Marco</b>'s <br />room",
  },

  16: {
    it: "La camera<br />di <b>Eldda</b>",
    en: "<b>Eldda</b>'s <br />room",
  },

  17: {
    it: "<b>Maratea</b>, una miscela<br /> profumata di <b>mare</b> e di <b>terra</b>",
    en: "<b>Maratea</b>, is a scent of<br /> <b>land</b> and <b>sea</b> mixed together",
  },

  18: {
    it: "<b>Maratea</b> contiene i codici<br /> di un’anima quasi isolana",
    en: "<b>Maratea</b> contains the codes<br /> of an almost insular soul",
  },

  19: {
    it: "un territorio dai <b>sapori</b><br /> e <b>profumi</b> sorprendenti,<br /> che la <b>basilicata</b> preserva<br /> sulle sue coste ancora<br /> selvagge e protette",
    en: "A territory of surprising <b>tastes</b><br /> and <b>flavours</b> that <b>Basilicata</b><br /> region preserves as an unreal and<br /> unexpected treasure on its<br /> shores still wild and protected",
  },

  20: {
    it: "Vivi il <b>tuo<br /> tempo</b> nella<br /> nostra casa",
    en: "Experience<br /> <b>your time</b><br /> in our home",
  },

  21: {
    it: "Possiamo organizzare pranzi e cene su richiesta al momento della prenotazione, con un costo extra.",
    en: "We can organize lunches and dinners upon request at the time of booking, with an extra cost.",
  },

  22: {
    it: "<b>Check-In</b><br />Dalle 14.00 alle 18.00, oltre l’orario indicato sarà nostra premura lasciare un contatto telefonico per l’accoglienza in casa.",
    en: "<b>Check-In</b><br />From 2.00pm to 6.00pm, beyond the indicated time, we will be happy to leave a telephone contact to welcome you to the house.",
  },

  23: {
    it: "<b>Check-Out</b><br />Entro le 12.00.",
    en: "<b>Check-Out</b><br />By 12.00.",
  },

  24: {
    it: "Consigliamo di raggiungere Maratea in auto, in quanto ha tante piccole frazioni sulla costa e in collina, raggiungibili solo in auto.<br /><br />Se dovesse servire un’auto a noleggio con autista preghiamo di farci sapere in anticipo.",
    en: "We recommend reaching Maratea by car, as it has many small hamlets on the coast and in the hills, reachable only by car.<br />If you need a rental car with driver, please let us know in advance.",
  },

  25: {
    it: "",
    en: "",
  },

  26: {
    it: "",
    en: "",
  },

  27: {
    it: "Casa:",
    en: "The House:",
  },

  28: {
    it: "prenota",
    en: "book now",
  },

  29: {
    it: "L’intera casa sarà riservata in <b>esclusiva per te</b>.",
    en: "The entire house will be reserved <b>exclusively for you</b>.",
  },

  30: {
    it: "Intera Casa",
    en: "Entire House",
  },

  31: {
    it: "Piano Terra",
    en: "Ground Floor",
  },

  32: {
    it: "Primo Piano",
    en: "First Floor",
  },

  33: {
    it: "Arrivare in auto",
    en: "Arrive by car",
  },

  34: {
    it: "Da Bari",
    en: "From Bari",
  },

  35: {
    it: "Prendere E843, S.da Statale 106 Jonica/E90, SS653, SS585 e SS 18 Tirrena Inferiore in direzione di Strada Provinciale Maratea - Castello a Maratea.",
    en: "Take E843, S.da Statale 106 Jonica/E90, SS653, SS585 and SS 18 Tirrena Inferiore in the direction of Strada Provinciale Maratea - Castle in Maratea.",
  },

  36: {
    it: "Da Napoli",
    en: "From Naples",
  },

  37: {
    it: "Seguire A3 e E45 in direzione di SS585 a Lagonegro. Prendere l’uscita Lagonegro Nord da E45. Continuare su SS585 fino alla vostra destinazione a Maratea.",
    en: "Follow A3 and E45 in the direction of SS585 to Lagonegro. Take the Lagonegro Nord exit from E45. Continue on SS585 until your destination in Maratea.",
  },

  38: {
    it: "Da Potenza",
    en: "From Potenza",
  },

  39: {
    it: "Prendere E847. Guidare da SS95var, SS 598 di Fondo Valle d’Agri/SS598, E45 e SS585 a Trecchina. Prendere Strada Provinciale 3 Tirrena/SP3 in direzione di SS 18 Tirrena Inferiore a Maratea.",
    en: "Take E847. Drive from SS95var, SS 598 di Fondo Valle d&#39;Agri/SS598, E45 and SS585 to Trecchina. Take Provincial Road 3 Tirrena/SP3 in the direction of SS 18 Tirrena Inferiore in Maratea",
  },

  40: {
    it: "Arrivare in treno",
    en: "Arriving by train",
  },

  41: {
    it: "La stazione di Maratea dista:<br /> 3 h e 40 min. dalla stazione di Roma Termini;<br /> 2 h e 20 min. dalla stazione di Napoli Centrale;<br /> 6 h e 30 min. dalla stazione di Bari Centrale.",
    en: "Maratea station is located at 3 hours and 40 minutes from Roma Termini station; - 2 hours and 20 minutes from Napoli Centrale station; - 6 hours and 30 minutes from Bari Centrale station.",
  },

  42: {
    it: "Arrivare in aereo",
    en: "Arriving by plane",
  },

  43: {
    it: "Non è difficile raggiungere Maratea in aereo sia con voli di linea sia con voli privati ; è a metà strada tra gli aeroporti di Napoli e di Lamezia Terme con voli di linea e low cost da varie città italiane ed europee.",
    en: "It is not difficult to reach Maratea by plane with both scheduled and private flights; it&#39;s halfway road between the airports of Naples and Lamezia Terme with scheduled and low cost flights from various Italian and European cities.",
  },

  44: {
    it: "Una volta arrivati in aereo è possibile proseguire in treno o in auto:",
    en: "Once you arrive by plane you can continue by train or car:",
  },

  45: {
    it: "- Dall'<b>Aeroporto di Napoli Capodichino</b> (sigla Iata NAP): in auto circa 220 km, in 2h15; in treno 2h10 (+ taxi per raggiungere la stazione di Napoli C., dai 20 ai 30 minuti)",
    en: "- From Naples Capodichino Airport (Iata NAP): by car about 220 km, in 2h15; by train 2h10 (+ taxi to reach the station of Naples C., from 20 to 30 minutes)",
  },

  46: {
    it: "- Dall'<b>Aeroporto di Lamezia Terme</b> (sigla Iata SUF) : in auto circa 146 km, in 2h, in treno 1h30/2h in funzione del tipo di treno (+ taxi per raggiungere Stazione di Lamezia Terme, meno di 5 minuti)",
    en: "- From Lamezia Terme Airport (Iata SUF): by car approximately 146 km, in 2 hours, by train 1h30/2h depending on the type of train ( taxi to reach Lamezia Terme station, less than 5 minutes)",
  },

  47: {
    it: "Arrivare in barca",
    en: "Arriving by boat",
  },

  48: {
    it: "Il <u>Porto di Maratea</u> è uno dei pochi approdi, se non addirittura l'unico, veramente attrezzato nel tratto di mare che va da Salerno a Vibo Valentia e può ospitare barche anche di grandi dimensioni.",
    en: "The Port of Maratea is one of the few, if not the only, truly equipped landing places in the stretch of sea that goes from Salerno to Vibo Valentia and can accommodate even large boats.",
  },

  49: {
    it: "Le Camere",
    en: "Rooms",
  },

  50: {
    it: "Il Living",
    en: "Living",
  },

  51: {
    it: "Storia della Casa",
    en: "The House's history",
  },

  52: {
    it: "<b>“La casa di Maratea”</b> è la nostra nuova proprietà a Marina di Mareta, in Basilicata sulla costa di Maratea.",
    en: "<b>“La casa di Maratea”</b> is our new property located in the Marina di Maratea area in Basilicata on the Maratea coast.",
  },

  53: {
    it: "La casa è di 160 mq, può ospitare sei persone ed è distribuita su due piani con tre camere da letto:",
    en: "The house is 160 m2 and can accommodate six people and is distributed over two floors with three bedrooms:",
  },

  54: {
    it: "Al piano terra il soggiorno, la cucina, la sala pranzo e la camera matrimoniale <b>“Marco”</b> con spogliatoio e bagno;",
    en: "On the ground floor the living room, the kitchen, the dining room and the <b>“Marco”</b> double bedroom with dressing room and bathroom;",
  },

  55: {
    it: "esplora",
    en: "explore",
  },

  56: {
    it: "l'indirizzo",
    en: "address",
  },

  57: {
    it: "i nostri contatti",
    en: "our contacts",
  },

  58: {
    it: "la nostra email",
    en: "our email",
  },

  59: {
    it: "Ottieni indicazioni",
    en: "Get indications",
  },

  60: {
    it: "Fotografie",
    en: "Photos",
  },

  61: {
    it: "Design & Sviluppo",
    en: "Design & Development",
  },

  62: {
    it: "i nostri instagram",
    en: "Our Instagram",
  },

  63: {
    it: "Cellulare",
    en: "Mobile",
  },
  64: {
    it: "Al primo piano le altre due camere: <b>“Wanda”</b> con letto matrimoniale e bagno ed <b>“Eldda”</b> con due letti singoli, bagno e un piccolo terrazzo.",
    en: "On the first floor the other two bedrooms: <b>“Wanda”</b> with double bed and bathroom and <b>“Eldda”</b> with two single beds, bathroom and a small terrace.",
  },
  65: {
    it: "La casa può ospitare 6 persone.",
    en: "The house can accommodate 6 people.",
  },
  66: {
    it: "Il terrazzo e la casa affacciano sul golfo di Policastro e la Costa di Maratea, contornata da un giardino privato e il suo parcheggio.",
    en: "The terrace and the house overlook the Gulf of Policastro and the Maratea Coast, surrounded by a private garden and its parking.",
  },
  67: {
    it: "Prenotazioni",
    en: "Reservations",
  },
};
export default translations;
